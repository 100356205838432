import React, { useState, useEffect } from "react";

import api from "utils/API";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import { ButtonTextMUI as Button } from "utils/componentsStyle";
import { red, green } from "@material-ui/core/colors";
import { Grid, Typography, TextField } from "@material-ui/core";

export default function ModalCheckEquipStatus({
  open,
  handleClose,
  row,
  setinitialCharge,
}) {
  const [comment, setcomment] = React.useState("");
  const [worker, setworker] = React.useState({});

  const lastWork = row.id_tarea_padre
  const patient = row.id_ficha.id_paciente

  useEffect(() => {
    api.get(`/api/fichapersonal/?usuario=${lastWork.id_usuarios_id}`
    ).then(response => {
      setworker(response.data[0])
    })
  }, []);


  const handleEndWork = () => {
    const body = {
      comentarios: comment,
    }
    api.patch(`api/tareaspaciente/${row.id}`, body).then((response) => {
      handleClose();
      setinitialCharge(true);
    });
  }
  

  console.log(row)



  const _content_ = <div>
     <Grid container direction="row" justify="center" alignItems="flex-start" item xs={12} style={{ margin: "10px 0 10x 0" }}>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item  xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
        {`Paciente: ${patient.nombre} ${patient.apellido_paterno} ${patient.apellido_materno}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item  xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Dirección: ${patient.direccion_calle} ${patient.direccion_numero} casa/dpto ${patient.direccion_casa_depto}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item  xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Comuna: ${patient.direccion_comuna}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item  xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Región: ${patient.direccion_region || "No definido"}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item  xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Fecha revisión: ${row.fecha_termino ? moment(row.fecha_termino).format("YYYY-MM-DD"): "-"}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item  xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Usuario: ${worker.nombres} ${worker.apellido_paterno} ${worker.apellido_materno}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="flex-start" alignItems="flex-start" item  xs={12} style={{ padding: "5px" }}>
        <Typography variant="caption" display="block" gutterBottom>
          {`Comentario previo: ${lastWork.comentarios || "-"}`}
        </Typography>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center" item xs={12}  style={{ padding: "5px" }}>
           <TextField 
              variant="outlined"
              multiline
              rows={5}
              label={`Agregar Comentario`}
              placeholder={``}
              value={comment}
              onChange={(e) => { setcomment(e.target.value)}}
            />
       
      </Grid>
    </Grid>

  </div>



  const _actions_ = <>
    <Button
      mr={2}
      variant="contained"
      className="text-white"
      color="primary"
      onClick={() => handleEndWork()}
    >
      Aceptar
    </Button> 
    <Button
      mr={2}
      variant="contained"
      style={{ backgroundColor: red[500], marginRight: "10px" }}
      className="text-white"
      onClick={() => handleClose()}
    >
      Cancelar
    </Button>
  </>



  return (
    <ModalDialog
      open={open}
      onClose={handleClose}
      title={`Estado Equipo`}
      _content_={_content_}
      _actions_ = {_actions_}
      maxWidth="xs"
    />
  );
}
