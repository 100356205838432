import React, {useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    TextField,
    Button,
} from '@material-ui/core';
import {red, green, indigo} from "@material-ui/core/colors";
import ModalDialog from "views/Widgets/Common/ModalDialog";
import api from "utils/API";
import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";
import {
    MuiPickersUtilsProvider,
    KeyboardDateTimePicker,
} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import moment from "moment-timezone";
import "moment/locale/es";
moment.locale("es");


const useStylesTemplate = makeStyles(styles);

export default function ModalEndVisit({
    open,
    handleClose,
    row,
    array,
    setArray,
    setinitialCharge
}) {
    const classesTemplate = useStylesTemplate();
    const [rowWork, setrowWork] = useState(row);
    const [validation, setvalidation] = useState(false);
    const [changeButtons, setchangeButtons] = useState(true);
    const [chargeData, setchargeData] = useState(true);
    const [reason, setreason] = useState("Realizada");
    const [view, setview] = useState("Realizada");
    const [motive, setmotive] = useState("");
    const [endDate, setendDate] = useState(moment(rowWork.fecha_hora).add(1, "hours"));

    useEffect(() => {
        validateForm();
        if (changeButtons) {
            setchangeButtons(false);
        }
        if (chargeData){
            initialInfo()
        }
    });

    const initialInfo = () => {
        setchargeData(false);
    };

    const validateForm = () => {
        const arrayValidation = [
            (view === "Realizada" ||
                (view !== "Realizada" && motive !== "")
            )
        ];
        if (arrayValidation.every((item) => item === true)) {
            setvalidation(true);
        } else {
            setvalidation(false);
        }
    };

    const handleOnChange = (value, set) => {
        set(value);
    };

    const updateValue = () => {
        let body = {
            estado: view,
            motivo_rechazo: motive,
            fecha_final: endDate.format("YYYY-MM-DD HH:mm:ss"),
        }
        const tempArray = array;
        if (rowWork.id_tarea_padre && rowWork.tipo_tarea === "HabilitacionDomicilio") api.patch(`api/tareaspaciente/${rowWork.id_tarea_padre}`, body).then((result) => {});
        api.patch(`api/tareaspaciente/${rowWork.id}`, body).then((result) => {
            if(array){
                setArray(tempArray.map((subRow) => {
                    const tempSubRow = {...subRow};
                    if (rowWork.id === subRow.id){
                        tempSubRow.estado = view
                    }
                    return {...tempSubRow}
                }));
                if (rowWork.tipo_tarea === "Telemedicina"){
                    api.post(`api/integracion/transcribir/llamada/tarea/${rowWork.id}`).then((result) => {
                        handleClose();
                    });

                }
            } else {
                if (setinitialCharge){
                    setinitialCharge(true)
                }
                handleClose();
            }
        });
    };

    const handleReason = (row, view) => {
        setreason(row)
        setview(view)
    }

    const _content_ = <div className="form-row" style={{ marginBottom: "1rem" }}>
        <Grid
            container
            direction="row"
            justify="center"
            alignItems="center"
            item
            xs={12}
        >
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <Typography variant="caption" display="block" gutterBottom>
                    ¿Qué ocurrió con la visita?
                </Typography>
            </Grid>


            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0"}}
            >
                <div className={classesTemplate.buttonGroup}>
                    <Button
                        mr={2}
                        variant="contained"
                        style={{
                            backgroundColor: reason === "Realizada" ? indigo[500] : indigo[50],
                            color:  reason === "Realizada" ? indigo[50] : indigo[500],
                        }}
                        onClick={() => handleReason("Realizada", "Realizada")}
                    >
                        Realizada
                    </Button>
                    <Button
                        mr={2}
                        variant="contained"
                        style={{
                            backgroundColor: reason === "Realizada con atraso" ? indigo[500] : indigo[50],
                            color:  reason === "Realizada con atraso" ? indigo[50] : indigo[500],
                        }}
                        onClick={() => handleReason("Realizada con atraso", "RealizadaAtraso")}
                    >
                        Realizada con atraso
                    </Button>
                    <Button
                        mr={2}
                        variant="contained"
                        style={{
                            backgroundColor: reason === "Cancelada por el paciente" ? indigo[500] : indigo[50],
                            color:  reason === "Cancelada por el paciente" ? indigo[50] : indigo[500],
                        }}
                        onClick={() => handleReason("Cancelada por el paciente", "CanceladaPaciente")}
                    >
                        Cancelada por el paciente
                    </Button>
                </div>
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
            >
                {view !== "Realizada" &&
                <TextField
                    label={`Motivo`}
                    multiline
                    value={motive}
                    rows={4}
                    onChange={(event) => { handleOnChange(event.target.value , setmotive)}}
                    variant="outlined"
                    style={{width: "60%"}}
                />
                }
            </Grid>
            <Grid
                container
                direction="row"
                justify="center"
                alignItems="center"
                item
                xs={12}
                style={{margin: "10px 0 0 0"}}
            >
                <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        format="DD/MM/YYYY HH:mm"
                        margin="normal"
                        label={"Fecha Término"}
                        placeholder="Fecha Término"
                        onChange={setendDate}
                        value={endDate}
                        inputVariant={"outlined"}
                    />
                </MuiPickersUtilsProvider>

            </Grid>
        </Grid>
    </div>

    const _actions_ = <>
        { validation ?
        <Button
            mr={2}
            variant="contained"
            className="text-white"
            style={{backgroundColor: green[500], margin: "0 5px 0 0"}}
            onClick={updateValue}
        >
            Guardar
        </Button>
        :
        <Button
            disabled
            mr={2}
            variant="contained"
            className="text-white"
        >
            Guardar
        </Button>
        }
        <Button
            mr={2}
            variant="contained"
            style={{backgroundColor: red[500], marginRight: "10px"}}
            className="text-white"
            onClick={handleClose}
        >
            Cerrar
        </Button>
    </>


    return (
        <ModalDialog
        open={open}
        onClose={handleClose}
        title={"Tarea"}
        _content_={_content_}
        _actions_ = {_actions_}
    />
  );
}